export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')
];

export const server_loads = [0,5,6,4,7];

export const dictionary = {
		"/": [~8],
		"/barang": [~9],
		"/barang/[item]": [~10],
		"/barang/[item]/negeri/[negeri]": [~11],
		"/barang/[item]/negeri/[negeri]/[daerah]": [~12],
		"/kategori/[group]": [~13],
		"/kategori/[group]/[category]": [~14],
		"/kategori/[group]/[category]/[subcategory]": [~15],
		"/kedai": [~16,[2]],
		"/kedai/[premise]": [~17,[2]],
		"/kedai/[premise]/barang/[item]": [~18,[2]],
		"/kedai/[premise]/kategori/[group]": [~19,[2]],
		"/kedai/[premise]/kategori/[group]/[category]": [~20,[2]],
		"/kedai/[premise]/kategori/[group]/[category]/[subcategory]": [~21,[2]],
		"/my": [22,[3]],
		"/my/about": [24,[3]],
		"/my/category": [25,[3,5]],
		"/my/category/health-beauty": [26,[3,5,6]],
		"/my/contact-us": [27,[3]],
		"/my/cpra": [28,[3]],
		"/my/disclaimer": [29,[3]],
		"/my/gdpr": [30,[3]],
		"/my/pdpa": [31,[3]],
		"/my/refund": [32,[3]],
		"/my/terms": [33,[3]],
		"/my/[others]": [23,[3,4]],
		"/negeri/[negeri]": [~34],
		"/negeri/[negeri]/daerah/[daerah]": [~35],
		"/negeri/[negeri]/daerah/[daerah]/kategori/[group]": [~36],
		"/negeri/[negeri]/daerah/[daerah]/kategori/[group]/[category]": [~37],
		"/negeri/[negeri]/daerah/[daerah]/kategori/[group]/[category]/[subcategory]": [~38],
		"/negeri/[negeri]/kategori/[group]": [~39],
		"/negeri/[negeri]/kategori/[group]/[category]": [~40],
		"/negeri/[negeri]/kategori/[group]/[category]/[subcategory]": [~41],
		"/sitemap": [42,[7]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';